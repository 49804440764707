<template>
  <b-container>
    <div class="f-radius b-white row pt-4 pb-4">
      <div class="space-between w-100 d-inline-flex align-items-center">
        <img @click="preDay" class="rotate-180 cursor-pointer" src="@/assets/images/icons/ic_next.svg" alt=""
             height="25">
        <p class="mb-0" @click="showDatePicker = !showDatePicker" data-target="show-date-picker">
          {{ dateFormatWithDay(formatDate) }}
          <img class="cursor-pointer calendar-icon"
               src="@/assets/images/icons/ic_calendar.svg" alt="" width="25" data-target="show-date-picker">
        </p>
        <img @click="nextDay" class="cursor-pointer" src="@/assets/images/icons/ic_next.svg" alt="" height="25">
      </div>
      <div class="position-relative w-100 d-flex justify-content-center">
        <div v-click-out="closeDatepicker" class="position-absolute z-index-2">
          <datepicker v-model="currentDate"
                      :bootstrap-styling="true"
                      v-if="showDatePicker"
                      :highlighted="highlighted"
                      :inline="true"
                      :full-month-name="true"
                      :language="languages.ja"
                      format="yyyy/MM/dd"></datepicker>
        </div>
      </div>
    </div>
    <div class="f-radius b-pink row pt-2 pb-2 text-white mt-5">
      <img src="@/assets/images/icons/ic_people.svg" alt="" width="32">
      <p class="ml-2 mb-0 base-font-size">{{ $t('home.number_of_customers') }}</p>
    </div>
    <div class="detail">
      <div>
        <p class="label base-font-size">{{ $t('home.personal') }}</p>
        <div class="row">
          <div class="col-4">
            <div class="bg-white p-3-2">
              <p class="text-underline mb-0 cursor-pointer"
                 @click="goto('search', {
                   range: 0,
                   status: 0,
                 })"
              >
                <span :class="{ 'text-bold': result.staff.reserve_customer > 0 }">{{ result.staff.reserve_customer }}</span><span class="man">{{ $t('home.man') }}</span>
              </p>
            </div>
            <p class="description">{{ $t('home.before_come_store') }}</p>
          </div>
          <div class="col-4">
            <div class="bg-white p-3-2">
              <p class="text-underline mb-0 cursor-pointer"
                 @click="goto('search', {
                   range: 0,
                   status: 1,
                 })"
              >
                <span :class="{ 'text-bold': result.staff.visited_customer > 0 }">{{ result.staff.visited_customer }}</span><span class="man">{{ $t('home.man') }}</span>
              </p>
            </div>
            <p class="description">{{ $t('home.visiting_store') }}</p>
          </div>
          <div class="col-4">
            <div class="bg-white p-3-2">
              <p class="text-underline mb-0 cursor-pointer"
                 @click="goto('search', {
                   range: 0,
                   status: 2,
                 })"
              >
                <span :class="{ 'text-bold': result.staff.paid_customer > 0 }">{{ result.staff.paid_customer }}</span><span class="man">{{ $t('home.man') }}</span>
              </p>
            </div>
            <p class="description">{{ $t('home.settled') }}</p>
          </div>
        </div>
      </div>
      <div>
        <p class="label base-font-size">{{ $t('home.store') }}</p>
        <div class="row">
          <div class="col-4">
            <div class="bg-white p-3-2">
              <p class="text-underline mb-0 cursor-pointer"
                 @click="goto('search', {
                   range: 1,
                   status: 0,
                 })"
              >
                <span :class="{ 'text-bold': result.shop.reserve_customer > 0 }">{{ result.shop.reserve_customer }}</span><span class="man">{{ $t('home.man') }}</span>
              </p>
            </div>
            <p class="description">{{ $t('home.before_come_store') }}</p>
          </div>
          <div class="col-4">
            <div class="bg-white p-3-2">
              <p class="text-underline mb-0 cursor-pointer"
                 @click="goto('search', {
                   range: 1,
                   status: 1,
                 })"
              >
                <span :class="{ 'text-bold': result.shop.visited_customer > 0 }">{{ result.shop.visited_customer }}</span><span class="man">{{ $t('home.man') }}</span>
              </p>
            </div>
            <p class="description">{{ $t('home.visiting_store') }}</p>
          </div>
          <div class="col-4">
            <div class="bg-white p-3-2">
              <p class="text-underline mb-0 cursor-pointer"
                 @click="goto('search', {
                   range: 1,
                   status: 2,
                 })"
              >
                <span :class="{ 'text-bold': result.shop.paid_customer > 0 }">{{ result.shop.paid_customer }}</span><span class="man">{{ $t('home.man') }}</span>
              </p>
            </div>
            <p class="description">{{ $t('home.settled') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="f-radius b-pink row pt-2 pb-2 text-white mt-5">
      <img src="@/assets/images/icons/ic_chart.svg" alt="" width="32">
      <p class="ml-2 mb-0 base-font-size">{{ $t('home.earnings') }}</p>
    </div>
    <div class="earn">
      <div class="row">
        <div class="col-6">
          <div class="bg-white p-3-2">
            <p class="mb-0">
              ¥
              <span :class="{ 'text-bold': result.sales.staff_sales > 0 }">
                {{ new Intl.NumberFormat('ja').format(result.sales.staff_sales) }}
              </span>
            </p>
          </div>
          <p class="description">{{ $t('home.personal') }}</p>
        </div>
        <div class="col-6">
          <div class="bg-white p-3-2">
            <p class="mb-0">
              ¥
              <span :class="{ 'text-bold': result.sales.shop_sales > 0 }">
                {{ new Intl.NumberFormat('ja').format(result.sales.shop_sales) }}
              </span>
            </p>
          </div>
          <p class="description">{{ $t('home.store') }}</p>
        </div>
      </div>
    </div>
    <div class="f-radius b-pink row pt-2 pb-2 text-white mt-5">
      <img src="@/assets/images/icons/ic_chart.svg" alt="" width="32">
      <p class="ml-2 mb-0 base-font-size">{{ $t('home.forecast_earnings') }}</p>
    </div>
    <div class="forecast-earn">
      <div class="row">
        <div class="col-6">
          <div class="bg-white p-3-2">
            <p class="mb-0">
              ¥
              <span :class="{ 'text-bold': result.sales_plan.staff_sales > 0 }">
                {{ new Intl.NumberFormat('ja').format(result.sales_plan.staff_sales) }}
              </span>
            </p>
          </div>
          <p class="description">{{ $t('home.personal') }}</p>
        </div>
        <div class="col-6">
          <div class="bg-white p-3-2">
            <p class="mb-0">
              ¥
              <span :class="{ 'text-bold': result.sales_plan.shop_sales > 0 }">
                {{ new Intl.NumberFormat('ja').format(result.sales_plan.shop_sales) }}
              </span>
            </p>
          </div>
          <p class="description">{{ $t('home.store') }}</p>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <form :action="formAction" method="post" target="_blank">
        <input type="hidden" name="company_id" :value="locationSelected.company_id">
        <input type="hidden" name="shop_group_id" :value="locationSelected.shop_group_id">
        <input type="hidden" name="shop_id" :value="locationSelected.shop_id">
        <input type="hidden" name="key" :value="appKey">
        <button type="submit" class="btn btn-pink"><i class="fa fa-calendar-alt"></i> 予約表参照</button>
      </form>
    </div>
  </b-container>
</template>

<script>
import {YYYY_MM_DD} from "@/utils/constants";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";
import karuteService from "@/services/karute-api";
import {ACTION_BACK_PAGE} from "@/store/auth/actions";
import {ACTION_SET_SEARCH_INFO} from "@/store/search/actions";

export default {
  name: "Home",
  data() {
    return {
      date: this.dateFormat(new Date()),
      currentDate: this.dateFormat(new Date()),
      highlighted: {
        dates: [
          new Date()
        ]
      },
      showDatePicker: false,
      result: {
        staff: {
          total: 0,
          reserve_customer: 0,
          visited_customer: 0,
          paid_customer: 0
        },
        shop: {
          total: 0,
          reserve_customer: 0,
          visited_customer: 0,
          paid_customer: 0
        },
        sales: {
          shop_sales: 0,
          staff_sales: 0
        },
        sales_plan: {
          shop_sales: 0,
          staff_sales: 0
        }
      },
      languages: lang
    }
  },
  methods: {
    goto(link, params = {}) {
      this.$store.dispatch(ACTION_SET_SEARCH_INFO, {
        from: this.formatDate,
        to: this.formatDate,
        range: params.range,
        status: params.status,
      }).then(() => {
        this.$router.push(link)
      })
    },
    closeDatepicker(el) {
      if (el.target.getAttribute('data-target') !== 'show-date-picker') {
        this.showDatePicker = false
      }
    },
    nextDay() {
      this.date = this.$moment(this.date).add(1, 'days').format(YYYY_MM_DD)
    },
    preDay() {
      this.date = this.$moment(this.date).subtract(1, 'days').format(YYYY_MM_DD)
    },
    getHomeInfo() {
      // Call API get_home_info, render data
      karuteService.getHomeInfo(this.formatDate).then((res) => {
        this.result = res.data.result
      })
    },
  },
  computed: {
    formatDate() {
      return this.dateFormat(this.date)
    },
    formAction() {
      return this.$config.homeUrl + '/sr_reserve_view/sheet_view/' +  this.$moment(this.date).format('YYYY-MM-DD')
    },
    appKey() {
      return process.env.VUE_APP_API_KEY
    }
  },
  watch: {
    locationSelected() {
      this.getHomeInfo()
    },
    date(val) {
      this.showDatePicker = false;
      this.currentDate = val
      this.getHomeInfo();
    },
    currentDate(val) {
      this.date = val
    }
  },
  created() {
    this.$store.dispatch(ACTION_BACK_PAGE, '')
  },
  mounted() {
    this.$nextTick(() => {
      this.getHomeInfo();
      window.scrollTo(0, 0)
    })
  },
  components: {
    Datepicker
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 3rem;
}

.base-font-size {
  font-size: $base-font-size !important;
}

.bv-example-row {
  .row-label {
    text-align: left;
    color: $white;
    background-color: $pink;
  }

  .row-item {
    &:not(:first-child) {
      height: 150px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid grey;
    }
  }
}

.row {
  margin-right: auto;
  margin-left: auto;
}

.f-radius {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 20px;
  font-size: 1.1rem;
  line-height: 30px;

  &.b-white {
    background: #ffffff;
  }

  &.b-pink {
    background: $pink;
    border-radius: 12px;
  }

  .space-between {
    justify-content: space-between;
  }
}

.detail {
  p.label {
    border-top: 2px solid;
    display: inline-block;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .col-4 {
    line-height: 1.7rem;
    max-width: 31%;
    padding-left: 0;
    padding-right: 0;

    &:not(:last-child) {
      margin-right: 3.5%;
    }
  }
}

div.bg-white {
  border-radius: 20px;
  display: flex;
  justify-content: center;
  .text-underline{
    text-decoration: underline;
  }

  p {
    font-size: 1.3rem;
    display: flex;
    align-items: center;

    span {
      font-size: 1.3rem;
      &.man {
        color: #999999;
      }
      &.text-bold {
        font-weight: 600;
        color: #2c3e50;
      }
    }
  }
}

p.description {
  margin-top: 0.4rem;
  text-align: center;
  font-size: 13px;
  line-height: 17px;
}

.earn, .forecast-earn {
  margin-top: 1rem;

  .col-6 {
    line-height: 1.7rem;
    max-width: 48.25%;
    padding-left: 0;
    padding-right: 0;

    &:not(:last-child) {
      margin-right: 3.5%;
    }
  }
}

.btn-pink {
  color: #fff;
  background-color: #e96282;
  border-color: $pink;

  &:hover {
    background-color: $pink;
    border-color: $pink;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.p-3-2 {
  padding: 1.5rem 1rem !important;
}

.calendar-icon {
  margin-top: -3px;
  margin-left: 8px;
}

.z-index-2 {
  z-index: 2;
}

</style>
